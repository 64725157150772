// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-life-js": () => import("./../../../src/pages/about/life.js" /* webpackChunkName: "component---src-pages-about-life-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about/me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-web-js": () => import("./../../../src/pages/web.js" /* webpackChunkName: "component---src-pages-web-js" */),
  "component---src-templates-design-js": () => import("./../../../src/templates/design.js" /* webpackChunkName: "component---src-templates-design-js" */),
  "component---src-templates-life-js": () => import("./../../../src/templates/life.js" /* webpackChunkName: "component---src-templates-life-js" */),
  "component---src-templates-photos-js": () => import("./../../../src/templates/photos.js" /* webpackChunkName: "component---src-templates-photos-js" */),
  "component---src-templates-web-js": () => import("./../../../src/templates/web.js" /* webpackChunkName: "component---src-templates-web-js" */)
}

